// Generated by Framer (d894e1b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["VrhY6Ph8o", "C2v0wimoN", "wf1e3_kMI"];

const serializationHash = "framer-yymNS"

const variantClassNames = {C2v0wimoN: "framer-v-1wfzpig", VrhY6Ph8o: "framer-v-85tp", wf1e3_kMI: "framer-v-267wbg"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transformTemplate = (_, t) => `translateX(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"variant 1": "VrhY6Ph8o", Mobile: "wf1e3_kMI", Tablet: "C2v0wimoN"}

const getProps = ({height, id, image, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "VrhY6Ph8o", VJ4Uhvx5_: image ?? props.VJ4Uhvx5_ ?? {src: "https://framerusercontent.com/images/8Jdaqcp2qip4FC8UQtoY3EkA54.png?scale-down-to=2048", srcSet: "https://framerusercontent.com/images/8Jdaqcp2qip4FC8UQtoY3EkA54.png?scale-down-to=512 512w, https://framerusercontent.com/images/8Jdaqcp2qip4FC8UQtoY3EkA54.png?scale-down-to=1024 1024w, https://framerusercontent.com/images/8Jdaqcp2qip4FC8UQtoY3EkA54.png?scale-down-to=2048 2048w, https://framerusercontent.com/images/8Jdaqcp2qip4FC8UQtoY3EkA54.png 2720w"}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, VJ4Uhvx5_, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "VrhY6Ph8o", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "wf1e3_kMI") return false
return true
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-yymNS", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-85tp", className)} data-framer-name={"variant 1"} layoutDependency={layoutDependency} layoutId={"VrhY6Ph8o"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-4bd2c1bf-fc7b-4b3a-a2a2-ae1380bb1a95, rgb(246, 243, 239))", ...style}} {...addPropertyOverrides({C2v0wimoN: {"data-framer-name": "Tablet"}, wf1e3_kMI: {"data-framer-name": "Mobile"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", sizes: "1360px", ...toResponsiveImage(VJ4Uhvx5_)}} className={"framer-nwxgfk"} data-framer-name={"square-bg"} layoutDependency={layoutDependency} layoutId={"ex63loMv9"} transformTemplate={transformTemplate} {...addPropertyOverrides({wf1e3_kMI: {background: {alt: "", fit: "fill", sizes: "809px", ...toResponsiveImage(VJ4Uhvx5_)}}}, baseVariant, gestureVariant)}/>{isDisplayed() && (<motion.div className={"framer-txdlwk"} data-framer-name={"left-vline"} layoutDependency={layoutDependency} layoutId={"YueUihyGC"} style={{backgroundColor: "var(--token-26bfb75c-ffdb-4ff7-8e64-ceeb6506c411, rgb(18, 23, 24))", opacity: 0.1}}/>)}{isDisplayed() && (<motion.div className={"framer-xgectj"} data-framer-name={"right-vline"} layoutDependency={layoutDependency} layoutId={"pN6keJxDZ"} style={{backgroundColor: "var(--token-26bfb75c-ffdb-4ff7-8e64-ceeb6506c411, rgb(18, 23, 24))", opacity: 0.1}}/>)}</motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-yymNS [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-yymNS .framer-1m4tzom { display: block; }", ".framer-yymNS .framer-85tp { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: row; flex-wrap: nowrap; height: 2000px; justify-content: space-between; overflow: hidden; padding: 0px 40px 0px 40px; position: relative; width: 1440px; }", ".framer-yymNS .framer-nwxgfk { aspect-ratio: 2.5 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 544px); left: 50%; position: absolute; top: 64px; width: 1360px; z-index: 0; }", ".framer-yymNS .framer-txdlwk { flex: none; height: 100%; left: 30px; overflow: hidden; position: absolute; top: 0px; width: 1px; z-index: 1; }", ".framer-yymNS .framer-xgectj { flex: none; height: 100%; overflow: hidden; position: absolute; right: 30px; top: 0px; width: 1px; z-index: 1; }", ".framer-yymNS.framer-v-267wbg .framer-85tp { width: 809px; }", ".framer-yymNS.framer-v-267wbg .framer-nwxgfk { height: var(--framer-aspect-ratio-supported, 324px); left: 50%; width: 809px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 2000
 * @framerIntrinsicWidth 1440
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"C2v0wimoN":{"layout":["fixed","fixed"]},"wf1e3_kMI":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"VJ4Uhvx5_":"image"}
 * @framerImmutableVariables false
 */
const FramerCSoPEChto: React.ComponentType<Props> = withCSS(Component, css, "framer-yymNS") as typeof Component;
export default FramerCSoPEChto;

FramerCSoPEChto.displayName = "background-n-line";

FramerCSoPEChto.defaultProps = {height: 2000, width: 1440};

addPropertyControls(FramerCSoPEChto, {variant: {options: ["VrhY6Ph8o", "C2v0wimoN", "wf1e3_kMI"], optionTitles: ["variant 1", "Tablet", "Mobile"], title: "Variant", type: ControlType.Enum}, VJ4Uhvx5_: {__defaultAssetReference: "data:framer/asset-reference,8Jdaqcp2qip4FC8UQtoY3EkA54.png?originalFilename=square-bg.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerCSoPEChto, [])